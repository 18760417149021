import React from 'react';

import Filters from '../../GenericComponents/Filters/Filters';
import Footer from '../../GenericComponents/Footer/Footer';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { SeasonalEventCountdown } from '../../GenericComponents/Seasonal/SeasonalEventCountdown';
import { SeasonalEventMerchants } from '../../GenericComponents/Seasonal/SeasonalEventMerchants';

import { getWidgetTitle } from './getWidgetTitle';
import ReviewRetailItems from './ReviewRetailItems';

const ReviewRetail: React.FC = () => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        loading,
        seasonal,
        data,
        showReviewsPosition,
        widgetTitle,
        translate,
        models,
        switchMerchantlinkToReview,
        name,
        genericSharedComponents: { HawkTabs, LoadMore, ModelCodeReviews, Spinner, Title },
      } = value;

      return (
        <>
          {loading && <Spinner />}
          <Title
            {...{
              title: getWidgetTitle({
                widgetTitle,
                name,
                translate,
                data,
                models: models as Record<string, number>,
              }),
            }}
          />
          {!switchMerchantlinkToReview && ['default', 'top'].indexOf(showReviewsPosition) >= 0 && (
            <ModelCodeReviews
              {...{
                className: 'review-widget',
              }}
            />
          )}
          {seasonal === 'event' && <SeasonalEventCountdown />}
          <Filters />
          <HawkTabs />
          <ReviewRetailItems />
          {!switchMerchantlinkToReview && showReviewsPosition === 'bottom' && (
            <ModelCodeReviews
              {...{
                className: 'review-widget-bottom',
              }}
            />
          )}
          {seasonal === 'event' && <SeasonalEventMerchants />}
          {(seasonal !== 'event' || (seasonal === 'event' && !data.event)) && (
            <LoadMore
              {...{
                className: switchMerchantlinkToReview ? 'review-merchantlink' : null,
              }}
            />
          )}
          <Footer
            {...{
              showDescription: true,
              showPoweredBy: true,
              className: switchMerchantlinkToReview ? 'review-merchantlink' : null,
            }}
          />
        </>
      );
    }}
  </Consumer>
);

export default ReviewRetail;
