import React, { useEffect } from 'react';

import { getClickType } from '../../../../sharedModules/getClickType';
import classNames from 'classnames';

import { useSortSimilarAtLastPosition } from '../../../hooks/useSortSimilarAtLastPosition';
import { useHawkWidgetContext } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { AmazonPrimeShipping } from '../../GenericComponents/Shipping';
import itemStyles from '../Main/styles/review-items.css';

const ReviewRetailItems: React.FC = () => {
  const {
    articleUrl,
    switchMerchantlinkToReview,
    translate,
    genericSharedComponents: { AffiliateLink, Button, GridItems, DealImage, MainPrice },
    dealData,
    sortSimilarAtLastPosition,
    loading,
    showShipping,
  } = useHawkWidgetContext();

  useEffect(() => {
    useSortSimilarAtLastPosition(dealData, sortSimilarAtLastPosition);
  }, [loading]);

  return (
    <GridItems
      className={{
        gridItems: itemStyles['grid-items'],
        gridItemMain: classNames(
          itemStyles['grid-item-main'],
          switchMerchantlinkToReview && itemStyles['grid-item-main-merchantlink'],
        ),
        gridItemBlock: classNames(
          itemStyles['grid-item-block'],
          switchMerchantlinkToReview && itemStyles['grid-item-block-merchantlink'],
        ),
        gridItem: classNames(
          itemStyles['grid-item'],
          switchMerchantlinkToReview && itemStyles['grid-item-merchantlink'],
        ),
      }}
      widgetName={switchMerchantlinkToReview ? 'review' : 'review-merchantlink'}
    >
      {(deal): JSX.Element => {
        return (
          <>
            <DealImage key="image" deal={deal} />
            <React.Fragment key="merchant">
              <>
                <DealImage deal={deal} priority={['merchant']} />
                {showShipping && deal?.shipping?.url && deal?.shipping?.prime && (
                  <AmazonPrimeShipping deal={deal} showLink={false} />
                )}
              </>
            </React.Fragment>
            <React.Fragment key="priceLink">
              <>
                <MainPrice
                  deal={deal}
                  className={switchMerchantlinkToReview ? 'merchantlink-review' : 'container'}
                />
                <Button
                  deal={deal}
                  className={switchMerchantlinkToReview ? 'button-merchantlink-review' : undefined}
                />
                {switchMerchantlinkToReview && (
                  <AffiliateLink
                    deal={deal}
                    className="merchantlink-review-textbutton"
                    clickType={getClickType({
                      link: deal.offer.link,
                      articleUrl,
                      productType: deal.product_type,
                    })}
                  >
                    {translate('priceLinkAtMerchant', [deal?.merchant?.name ?? ''])}
                  </AffiliateLink>
                )}
              </>
            </React.Fragment>
          </>
        );
      }}
    </GridItems>
  );
};

export default ReviewRetailItems;
