import getDefaultTitle from '../../../modules/getDefaultTitle';
import type { APIData } from '../../../types/APIData';
import type { WidgetProps } from '../../../types/WidgetProps';

interface GetWidgetTitleProps {
  widgetTitle: WidgetProps['widgetTitle'];
  name: WidgetProps['name'];
  translate: WidgetProps['translate'];
  data: APIData;
  models: WidgetProps['models'];
}

export const getWidgetTitle = ({
  widgetTitle,
  name,
  translate,
  data,
  models,
}: GetWidgetTitleProps): string => {
  if (widgetTitle) {
    return widgetTitle;
  }
  if (name === 'BOT widget') {
    return translate('reviewLabelsTitle', [`${getDefaultTitle(data, models)}:`]);
  }
  return translate('reviewDefaultTitle', [getDefaultTitle(data, models)]);
};
