import React from 'react';

import getDefaultTitle from '../../../modules/getDefaultTitle';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import ReviewSubscriptionsItems from './ReviewSubscriptionsItems';

const ReviewSubscriptions: React.FC = () => (
  <Consumer>
    {(value): JSX.Element | null => {
      const {
        widgetTitle,
        translate,
        data,
        models,
        genericSharedComponents: { Title },
      } = value;
      let { dealData } = value;
      dealData = dealData || {};
      if (dealData && dealData.deals && dealData.deals.length > 0) {
        const className = dealData.deals.length <= 2 ? 'review-wide' : 'review';
        return (
          <div>
            <Title
              {...{
                title:
                  widgetTitle ||
                  translate('reviewDefaultTitle', [
                    getDefaultTitle(data, models as Record<string, number>),
                  ]),
              }}
            />
            <ReviewSubscriptionsItems className={className} />
          </div>
        );
      }
      return null;
    }}
  </Consumer>
);

export default ReviewSubscriptions;
