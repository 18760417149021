import React, { useContext } from 'react';

import { getHoverOverItems } from '../../../../sharedModules/getHoverOverItems';
import shouldDisplayUpfrontPrice from '../../../modules/shouldDisplayUpfrontPrice';
import ContractAllowance from '../../GenericComponents/ContractAllowance/ContractAllowance';
import { Context } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { HoverOver } from '../../GenericComponents/HoverOver';
import PriceButtonWrapper from '../../GenericComponents/PriceButtonWrapper/PriceButtonWrapper';
import { UpfrontPrice } from '../../GenericComponents/UpfrontPrice';
import ViewAt from '../../GenericComponents/ViewAt/ViewAt';
import itemStyles from '../Main/styles/review-items.css';

import styles from './styles/review-contracts-items.css';

const ReviewContractsItems: React.FC = () => {
  const {
    genericSharedComponents: { GridItems, Button, DealImage, DisplayName, MainPrice, PriceNote },
  } = useContext(Context);

  return (
    <GridItems
      className={{
        gridItems: itemStyles['grid-items'],
        gridItemMain: itemStyles['grid-item-main'],
        gridItemBlock: itemStyles['grid-item-block'],
        gridItem: itemStyles['grid-item'],
      }}
      widgetName="review"
    >
      {(deal): JSX.Element => {
        const hoverItems = getHoverOverItems(deal);
        return (
          <>
            <React.Fragment key="network">
              <DealImage deal={deal} priority={['network', 'merchant']} />
              <ContractAllowance deal={deal} type="length" />
            </React.Fragment>
            <DisplayName key="name" deal={deal} className={styles['display-name']} />
            <React.Fragment key="price">
              <DisplayName deal={deal} className={styles['display-name']} />
              {shouldDisplayUpfrontPrice(deal) && (
                <UpfrontPrice deal={deal} className="review-contracts" />
              )}
              <MainPrice deal={deal} className="above" />
              <PriceNote deal={deal} />
            </React.Fragment>
            <React.Fragment key="allowance">
              <ContractAllowance deal={deal} showLabel={true} type="minutes" className="review" />
              <ContractAllowance deal={deal} showLabel={true} type="texts" className="review" />
              <ContractAllowance deal={deal} showLabel={true} type="data" className="review" />
              {hoverItems.length > 0 && <HoverOver items={hoverItems} />}
            </React.Fragment>
            <React.Fragment key="allowanceMobile">
              <DealImage deal={deal} priority={['network', 'merchant']} />
              <ContractAllowance deal={deal} type="length" className="review" />
              <ContractAllowance deal={deal} showLabel={true} type="minutes" className="review" />
              <ContractAllowance deal={deal} showLabel={true} type="texts" className="review" />
              <ContractAllowance deal={deal} showLabel={true} type="data" className="review" />
              {hoverItems.length > 0 && <HoverOver items={hoverItems} />}
            </React.Fragment>
            <React.Fragment key="link">
              <Button deal={deal} />
              <ViewAt deal={deal} />
            </React.Fragment>
            <PriceButtonWrapper key="priceLink" deal={deal} />
          </>
        );
      }}
    </GridItems>
  );
};

export default ReviewContractsItems;
