import React, { useContext } from 'react';

import { Context } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import ViewAt from '../../GenericComponents/ViewAt/ViewAt';
import itemStyles from '../Main/styles/review-items.css';

import styles from './styles/review-subscriptions-items.css';

interface IReviewSubscriptionsItemsProps {
  className: string;
}

const ReviewSubscriptionsItems: React.FC<IReviewSubscriptionsItemsProps> = ({ className }) => {
  const {
    genericSharedComponents: { GridItems, Button, DealImage, DisplayName, MainPrice },
  } = useContext(Context);

  const useWideReview = className === 'review-wide';

  return (
    <GridItems
      className={{
        gridItems: useWideReview ? styles['grid-items-wide'] : itemStyles['grid-items'],
        gridItemMain: useWideReview ? styles['grid-item-main-wide'] : itemStyles['grid-item-main'],
        gridItemBlock: useWideReview
          ? styles['grid-item-block-wide']
          : itemStyles['grid-item-block'],
        gridItem: itemStyles['grid-item'],
      }}
      stacked={true}
      widgetName={className}
    >
      {(deal): JSX.Element => (
        <>
          <DealImage key="image" deal={deal} priority={['network']} />
          <DisplayName key="name" deal={deal} type="product" className={styles['display-name']} />
          <React.Fragment key="priceLink">
            <MainPrice deal={deal} />
            <Button deal={deal} />
          </React.Fragment>
          <ViewAt key="viewat" deal={deal} />
        </>
      )}
    </GridItems>
  );
};

export default ReviewSubscriptionsItems;
