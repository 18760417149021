import type { DealData } from '../../types/DealData';

type CallbackFn = ({ offers }) => void;

export default function useSortSimilarAtLastPosition(
  dealData: DealData,
  callback: CallbackFn,
): void {
  const reOrderedDeals = dealData?.deals;
  const findSimilarDealsIndexes = dealData?.deals
    .map((deal, index) => {
      if (deal.product_type === 200) {
        return index;
      }
      return null;
    })
    .filter((item) => item !== null);

  // iterate throught all similar deals indexes found and push them at the end of the array
  findSimilarDealsIndexes.filter((i) => i && reOrderedDeals.push(reOrderedDeals.splice(i, 1)[0]));

  callback({ offers: reOrderedDeals });
}
