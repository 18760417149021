import React from 'react';

import getDefaultTitle from '../../../modules/getDefaultTitle';
import Filters from '../../GenericComponents/Filters/Filters';
import Footer from '../../GenericComponents/Footer/Footer';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import ReviewContractsItems from './ReviewContractsItems';

const ReviewContracts: React.FC = () => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        loading,
        widgetTitle,
        data,
        models,
        translate,
        genericSharedComponents: { HawkTabs, Spinner, Title },
      } = value;

      return (
        <>
          {loading && <Spinner />}
          <Title
            {...{
              title:
                widgetTitle ||
                translate('reviewDefaultTitle', [
                  getDefaultTitle(data, models as Record<string, number>),
                ]),
            }}
          />
          <Filters />
          <HawkTabs />
          <ReviewContractsItems />
          <Footer
            {...{
              showDescription: true,
              showPoweredBy: true,
            }}
          />
        </>
      );
    }}
  </Consumer>
);

export default ReviewContracts;
